import { ROLE_MANAGER, ROLE_SYSADMIN, ROLE_TENANTADMIN, ROLE_USER } from 'aas/common/constant';
import MainLayout from 'base/layout/MainLayout';
import { lazy } from 'react';
import { Navigate } from 'react-router';

// project imports

import Loadable from 'ui-component/common/Loadable';
import ZPromptLoginExpired from 'ui-component/ZPromptLoginExpired';

//users
// const DashboardOverview = Loadable(lazy(() => import('app/views/dashboard/DashboardOverview')));
const dashboardRoutes = (isLoggedIn, userRole) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <ZPromptLoginExpired />,
    children: [
        // {
        //     path: '/overview',
        //     element: <DashboardOverview />
        // },
    ]
});

const MonthlyReport = Loadable(lazy(() => import('app/views/reporting/MonthlyReport')));
const PurchaseOrderHistory = Loadable(lazy(() => import('app/views/reporting/PurchaseOrderHistory')));
const reportingRoutes = (isLoggedIn, userRole) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <ZPromptLoginExpired />,
    children: [
        {
            path: '/report/monthly',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <MonthlyReport />
        },
        {
            path: '/report/pohistory',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <PurchaseOrderHistory />
        },
    ]
});


const ProductPage = Loadable(lazy(() => import('app/views/master/ProductPage')));
const CategoryPage = Loadable(lazy(() => import('app/views/master/CategoryPage')));
const ClientPage = Loadable(lazy(() => import('app/views/master/ClientPage')));
const SuppliersPage = Loadable(lazy(() => import('app/views/master/SuppliersPage')));
const SupplierViewPage = Loadable(lazy(() => import('app/views/master/SupplierViewPage')));
const CurrencyPage = Loadable(lazy(() => import('app/views/master/CurrencyPage')));
const UomPage = Loadable(lazy(() => import('app/views/master/UomPage')));
const TermsPage = Loadable(lazy(() => import('app/views/master/TermsPage')));
const TermTypePage = Loadable(lazy(() => import('app/views/master/TermTypePage')));
const masterDataRoutes = (isLoggedIn, userRole) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <ZPromptLoginExpired />,
    children: [
        {
            path: '/master/product',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <ProductPage />
            // <Navigate to="../login" />
        },
        {
            path: '/master/category',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <CategoryPage />
        },
        {
            path: '/master/client',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <ClientPage />
        },
        {
            path: '/master/supplier',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <SuppliersPage />
        },
        {
            path: '/master/supplier/view',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <SupplierViewPage />
        },
        {
            path: '/master/currency',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <CurrencyPage />
        },
        {
            path: '/master/uom',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <UomPage />
        },
        {
            path: '/master/terms',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <TermsPage />
        },
        {
            path: '/master/termtype',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <TermTypePage />
        },
    ]
});

const ProductInventory = Loadable(lazy(() => import('app/views/inventory/ProductInventory')));
const ProductInventoryView = Loadable(lazy(() => import('app/views/inventory/ProductInventoryView')));
const InventoryTransaction = Loadable(lazy(() => import('app/views/inventory/InventoryTransaction')));
const StockTakeList = Loadable(lazy(() => import('app/views/inventory/StockTakeList')));
const StockTakeAdminList = Loadable(lazy(() => import('app/views/inventory/StockTakeAdminList')));
const StockTakeCreate = Loadable(lazy(() => import('app/views/inventory/StockTakeCreate')));
const StockTakeCreateFlat = Loadable(lazy(() => import('app/views/inventory/StockTakeCreateFlat')));
const StockTakeView = Loadable(lazy(() => import('app/views/inventory/StockTakeView')));
const StockTakeAdjustInventory = Loadable(lazy(() => import('app/views/inventory/StockTakeAdjustInventory')));
const inventoryRoutes = (isLoggedIn, userRole) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <ZPromptLoginExpired />,
    children: [
        {
            path: '/inv/product',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <ProductInventory />
        },
        {
            path: '/inv/product/view/:ids',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <ProductInventoryView />
        },
        {
            path: '/inv/trans',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <InventoryTransaction />
        },
        {
            path: '/inv/stocktake',
            element: !userRole.includes(ROLE_MANAGER) ? <Navigate to="../403" /> : <StockTakeList />
        },
        {
            path: '/inv/stocktake/admin',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <StockTakeAdminList />
        },
        {
            path: '/inv/stocktake/new',
            element: !userRole.includes(ROLE_MANAGER) ? <Navigate to="../403" /> : <StockTakeCreate />
        },
        {
            path: '/inv/stocktake/new2',
            element: !userRole.includes(ROLE_MANAGER) ? <Navigate to="../403" /> : <StockTakeCreateFlat />
        },
        {
            path: '/inv/stocktake/view/:ids',
            element: !userRole.includes(ROLE_MANAGER) ? <Navigate to="../403" /> : <StockTakeView />
        },
        {
            path: '/inv/stocktake/adj',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <StockTakeAdjustInventory />
        },
    ]
});

const InvoiceList = Loadable(lazy(() => import('app/views/orders/invoice/InvoiceList')));
const InvoiceCreateUpdate = Loadable(lazy(() => import('app/views/orders/invoice/InvoiceCreateUpdate')));
const InvoiceView = Loadable(lazy(() => import('app/views/orders/invoice/InvoiceView')));
const DoList = Loadable(lazy(() => import('app/views/orders/do/DoList')));
const DoCreateUpdate = Loadable(lazy(() => import('app/views/orders/do/DoCreateUpdate')));
const DoView = Loadable(lazy(() => import('app/views/orders/do/DoView')));
const PoList = Loadable(lazy(() => import('app/views/orders/po/PoList')));
const PoReceiveList = Loadable(lazy(() => import('app/views/orders/po/PoReceiveList')));
const PoCreate = Loadable(lazy(() => import('app/views/orders/po/PoCreate')));
const PoView = Loadable(lazy(() => import('app/views/orders/po/PoView')));
const PoEdit = Loadable(lazy(() => import('app/views/orders/po/PoEdit')));
const orderRoutes = (isLoggedIn, userRole) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <ZPromptLoginExpired />,
    children: [
        {
            path: '/invoice',
            element: !userRole.includes(ROLE_USER) ? <Navigate to="../403" /> : <InvoiceList />
        },
        {
            path: '/invoice/create',
            element: !userRole.includes(ROLE_USER) ? <Navigate to="../403" /> : <InvoiceCreateUpdate />
        },
        {
            path: '/invoice/view',
            element: !userRole.includes(ROLE_USER) ? <Navigate to="../403" /> : <InvoiceView />
        },
        {
            path: '/do',
            element: !userRole.includes(ROLE_USER) ? <Navigate to="../403" /> : <DoList />
        },
        {
            path: '/do/create',
            element: !userRole.includes(ROLE_USER) ? <Navigate to="../403" /> : <DoCreateUpdate />
        },
        {
            path: '/do/view',
            element: !userRole.includes(ROLE_USER) ? <Navigate to="../403" /> : <DoView />
        },
        {
            path: '/po',
            element: !userRole.includes(ROLE_MANAGER) ? <Navigate to="../403" /> : <PoList />
        },
        {
            path: '/po/create',
            element: !userRole.includes(ROLE_MANAGER) ? <Navigate to="../403" /> : <PoCreate />
        },
        {
            path: '/po/edit',
            element: !userRole.includes(ROLE_MANAGER) ? <Navigate to="../403" /> : <PoEdit />
        },
        {
            path: '/po/view/:ids',
            element: !userRole.includes(ROLE_USER) ? <Navigate to="../403" /> : <PoView />
        },
        {
            path: '/po/receive',
            element: !userRole.includes(ROLE_USER) ? <Navigate to="../403" /> : <PoReceiveList />
        },

    ]
});


// admin
const Settings = Loadable(lazy(() => import('app/views/Settings')));
const adminRoutes = (isLoggedIn, userRole) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <ZPromptLoginExpired />,
    children: [
        {
            path: '/settings',
            element: !userRole.includes(ROLE_TENANTADMIN) ? <Navigate to="../403" /> : <Settings />
        },
    ]
});

// system admin
const SysAdminInvoiceDoManagement = Loadable(lazy(() => import('app/views/maintenance/SysAdminInvoiceDoManagement')));
const SysAdminPoManagement = Loadable(lazy(() => import('app/views/maintenance/SysAdminPoManagement')));
const SysAdminPlatformNotification = Loadable(lazy(() => import('app/views/maintenance/SysAdminPlatformNotification')));
const SysAdminTenantMailConfiguration = Loadable(lazy(() => import('app/views/maintenance/SysAdminTenantMailConfiguration')));
const sysAdminRoutes = (isLoggedIn, userRole) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <ZPromptLoginExpired />,
    children: [
        {
            path: '/maint/sysinvdo',
            element: !userRole.includes(ROLE_SYSADMIN) ? <Navigate to="../403" /> : <SysAdminInvoiceDoManagement />
        },
        {
            path: '/maint/syspo',
            element: !userRole.includes(ROLE_SYSADMIN) ? <Navigate to="../403" /> : <SysAdminPoManagement />
        },
        {
            path: '/maint/notification',
            element: !userRole.includes(ROLE_SYSADMIN) ? <Navigate to="../403" /> : <SysAdminPlatformNotification />
        },
        {
            path: '/maint/mailconf',
            element: !userRole.includes(ROLE_SYSADMIN) ? <Navigate to="../403" /> : <SysAdminTenantMailConfiguration />
        },
    ]
});


export const appRoutes = (isLoggedIn, userRole) => ([
    adminRoutes(isLoggedIn, userRole),
    dashboardRoutes(isLoggedIn, userRole),
    reportingRoutes(isLoggedIn, userRole),
    masterDataRoutes(isLoggedIn, userRole),
    inventoryRoutes(isLoggedIn, userRole),
    orderRoutes(isLoggedIn, userRole),
    sysAdminRoutes(isLoggedIn, userRole)
]);